<!-- 知识大讲堂 -->
<template>
  <div style="height: 100%">
    <div class="information" style="padding-bottom: 30px">
      <!-- 全部 -->
      <div class="center" v-for="item in list" :key="item.index">
        <router-link :to="'/home/classroom/particulars/' + item.GNId">
          <div class="Content">
            <div style="display: inline-block" class="ContentA">
              <img :src="item.ThemeImg" alt="" @error="handleImageError" />
            </div>
            <div style="display: inline-block; margin-left: 10px" class="ContentB">
              <span class="van-multi-ellipsis--l2" style="color: #000">{{ item.Title }}</span>
              <br />
              <span class="centertime van-multi-ellipsis--l3">{{ item.Summary }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Sticky } from 'vant'
Vue.use(Sticky)
import { setOpenId, getOpenId } from '@/utils/auth'
import { WeGetGoodNewsPage, WeGetGNTypeList } from '@/api/Hyapi'
export default {
  data() {
    return {
      active: 0,
      tabactive: 0,
      akId: 0, //tab栏
      //资讯列表
      listfrom: {
        page: 1,
        limit: 999,
        group: 2,
      }, //查询传参
      hidden: true, //没有更多数据了
      list: {},
      tabList: [],
    }
  },
  methods: {
    handleImageError(event) {
      event.target.src = 'https://lycaljdapi.dexian.ren//upload/images/2024/1/010e3f06-ef7.png'
    },
    // 切换资讯分类
    tabClick(e) {
      this.getList()
    },
    // 获取资讯分类
    getGNTypeList() {
      WeGetGNTypeList().then((res) => {
        this.tabList = res.data.data
      })
    },
    // 获取分页列表
    getList() {
      WeGetGoodNewsPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {}
            } else {
              this.list = res.data.data
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == '') {
                  this.list[i].AImgUrl = 'https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png'
                }
              }
            }
          }
        })
        .catch(() => {})
    },
  },
  mounted() {
    if (this.$route.query['openid']) {
      setOpenId(this.$route.query['openid'])
    }
    this.getGNTypeList()
    this.getList()
  },
}
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.center {
  border-bottom: 1px solid rgb(246, 243, 243);
}
</style>
